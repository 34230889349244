module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"bournemouth-repairs-website","short_name":"bournemouth-repairs","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","theme_color_in_head":false,"display":"minimal-ui","icon":"/opt/build/repo/node_modules/@servicesite/gatsby-theme-appliance-repair/src/images/favicon.png","legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"42eed66102219cb24b133ee62605e6ed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N84L6M3","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/@servicesite/gatsby-theme-appliance-repair/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bournemouth Repairs","title":"Bournemouth Repairs - Appliance Repairs in Bournemouth and surrounding areas!","area":"Bournemouth","googleMapsApiKey":"AIzaSyBgkCBaNIJuXJp3gXgLQKNI9amatChArn0","mapZoomLevel":9,"googleTagManagerId":"GTM-N84L6M3"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
